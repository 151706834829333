<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          WhatsBot
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            src="@/assets/images/pages/login-v2.svg"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Painel Administrativo👋
          </b-card-title>
          <b-card-text class="mb-2">
            Preencha os campos para prosseguir.
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >

              <!-- email -->
              <b-form-group
                label-for="username"
                label="Usuario"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Usuario"
                  rules="required"
                >
                  <b-form-input
                    id="username"
                    v-model="username"
                    name="login-username"
                    :state="errors.length > 0 ? false:null"
                    placeholder="fefedomangue"
                    autofocus
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="password">Senha</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Senha"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="login-password"
                      placeholder="Senha"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                variant="primary"
                type="submit"
                block
                :disabled="invalid"
              >
                Entrar
              </b-button>
            </b-form>
          </validation-observer>

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      username: '',
      password: '',
      status: '',
      // validation rules
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          const payload = { username: this.username, password: this.password }
          this.$store.dispatch('authadmin/login', payload).then(response => {
            if (response.token) {
              this.$router.go(0)
              this.$router
                .push({ name: 'painel' })
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Bem vindo!!',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Bem vindo amigo, tenha um bom trabalho hoje!!',
                    },
                  })
                })
                .catch(error => {
                  this.$refs.loginForm.setErrors(error.response.data.error)
                })
            }

            if (response.error === 1) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Erro',
                  icon: 'BellIcon',
                  text: '😧 Usuario ou Senha incorreto.',
                  variant: 'danger',
                },
              })
            }
          })
        }
      }).catch(error => {
        console.log(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Erro',
            icon: 'BellIcon',
            text: '😧 Erro ao se conectar no servidor, contate o administrador.',
            variant: 'danger',
          },
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
